import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Box,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  TableBody,
  makeStyles,
} from "@material-ui/core";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import axios from "axios";

import { utcToLocaleDateTime } from "../utils/timeConverter";

const useStyles = makeStyles(() => ({
  dropDown: {
    minWidth: 120,
  },
  textRed: {
    color: "#f50057",
  },
  textGreen: {
    color: "#445A3E",
  },
  textLink: {
    color: "#445A3E",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const Returns = ({ history, location: { state } }) => {
  const classes = useStyles();
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState(
    sessionStorage.getItem("SELECTED_AUCTIONHOUSE")
      ? JSON.parse(sessionStorage.getItem("SELECTED_AUCTIONHOUSE"))
      : state[0]
  );
  const [returnStats, setReturnsStats] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    let counter = 0;

    getReturnsStats();

    const id = setInterval(() => {
      counter += 1;
      if (counter === 60) {
        getReturnsStats();
        counter = 0;
      }
    }, 1000);

    function getReturnsStats() {
      axios
        .get(
          `${process.env.REACT_APP_RETURNS_API_URL}/returns/stats/${currentAuctionHouse.id}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem(
                "ACCESS_AUTH_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          const returnStats = res.data;
          setReturnsStats(returnStats);
        })
        .catch((err) => {
          console.error(err);
          if (err.response.status === 404) setErrorMessage("No Returns");
          else if (err.response.status === 401) logout();
          setReturnsStats([]);
        });
    }

    function logout() {
      sessionStorage.removeItem("ACCESS_TOKEN");
      history.replace("/");
    }

    return () => clearInterval(id);
  }, [currentAuctionHouse, history]);

  function handleAuctionHouseChange(e) {
    const value = e.target.value;
    const selectedAuctionnHouse = state.filter(
      (auctionHouse) => auctionHouse.name === value
    )[0];

    sessionStorage.setItem(
      "SELECTED_AUCTIONHOUSE",
      JSON.stringify(selectedAuctionnHouse)
    );
    setCurrentAuctionHouse(selectedAuctionnHouse);
  }

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const fullscreenHandle = useFullScreenHandle();

  const getStatus = (value) => {
    switch (value) {
      case 0:
        return "Open";
      case 1:
        return "Re-Lot";
      case 2:
        return "Closed";
      default:
        return null;
    }
  };

  return (
    <>
      <Grid container>
        {!isMobile && (
          <Grid container item justify="flex-end" xs={6}>
            <Button variant="outlined" onClick={fullscreenHandle.enter}>
              Enter fullscreen
            </Button>
          </Grid>
        )}
        <Grid
          container
          item
          justify={isMobile ? "center" : "flex-start"}
          xs={isMobile ? 12 : 6}
        >
          <FormControl variant="filled">
            <InputLabel htmlFor="auction-house">Auction House</InputLabel>
            <Select
              native
              value={currentAuctionHouse.name}
              onChange={handleAuctionHouseChange}
              inputProps={{
                name: "auctionHouse",
                id: "auction-house",
              }}
            >
              {state.map((auctionHouse) => {
                return (
                  <option key={auctionHouse.id} value={auctionHouse.name}>
                    {auctionHouse.name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <FullScreen handle={fullscreenHandle}>
        {returnStats.length !== 0 ? (
          <Container maxWidth="xl">
            <Box mt={3}>
              <Grid container spacing={8}>
                <Grid container item spacing={4}>
                  <Grid container item justify="center" sm={6}>
                    <Typography
                      variant={isMobile ? "h2" : "h1"}
                    >{`OPEN:`}</Typography>
                    <Typography
                      variant={isMobile ? "h2" : "h1"}
                      color="secondary"
                    >
                      {returnStats.open[0]
                        ? `\u00A0${returnStats.open[0].count}`
                        : " 0"}
                    </Typography>
                  </Grid>
                  <Grid container item justify="center" sm={6}>
                    <Typography
                      variant={isMobile ? "h2" : "h1"}
                    >{`RELOT:`}</Typography>
                    <Typography
                      variant={isMobile ? "h2" : "h1"}
                      color="primary"
                    >
                      {returnStats.open[1]
                        ? `\u00A0${returnStats.open[1].count}`
                        : " 0"}
                    </Typography>
                  </Grid>
                  <Grid container item justify="center" sm={6}>
                    <Typography
                      variant={isMobile ? "h2" : "h1"}
                    >{`CLOSED:`}</Typography>
                    <Typography
                      variant={isMobile ? "h2" : "h1"}
                      color="secondary"
                    >
                      {`\u00A0${returnStats.closed.count}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item sm={12}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Last Closed</TableCell>
                          <TableCell>Last Updated</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            {returnStats.lastClosed
                              ? utcToLocaleDateTime(returnStats.lastClosed)
                              : "0 Today"}
                          </TableCell>
                          <TableCell>
                            {utcToLocaleDateTime(returnStats.lastUpdated)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid container item sm={12}>
                  <Typography variant="h2">Returns</Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Location</TableCell>
                          <TableCell>Return Number</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Creation Date</TableCell>
                          <TableCell>Ticket Id</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {returnStats.returns.map((r) => {
                          return (
                            <TableRow key={r.id}>
                              <TableCell>{r.location}</TableCell>
                              <TableCell>{r.id}</TableCell>
                              <TableCell>{getStatus(r.status)}</TableCell>
                              <TableCell className={classes.textGreen}>
                                {utcToLocaleDateTime(r.creationDate)}
                              </TableCell>
                              <TableCell>
                                <a
                                  href={`https://portal.simoncharles.com/qms/tickets/${r.ticketId}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={classes.textLink}
                                >
                                  {r.ticketId}
                                </a>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Box>
          </Container>
        ) : errorMessage ? (
          <Container maxWidth="xl">
            <Box mt={3}>
              {" "}
              <Typography variant="h1">{errorMessage}</Typography>
            </Box>
          </Container>
        ) : (
          <CircularProgress />
        )}
      </FullScreen>
    </>
  );
};

export default Returns;
