import { createTheme  } from "@material-ui/core/styles";

export default createTheme ({
  palette: {
    primary: {
      light: "#697b64",
      main: "#445A3E",
      dark: "#2f3e2b",
      contrastText: "#fff",
    },
  },
});
