/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import statsScreenNewApi from "../../API/statsScreenNewAPI";
import { Table, Spinner, Alert, Row, Col, Pagination } from "react-bootstrap";
import InboundDetailsModal from "../Inbound/InboundDetailsModal";
import { utcToLocaleDateTime } from "../../utils/timeConverter";
// import useTimer from "../../utils/useTimer";

const Inbounds = ({ auctionHouse }) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [maximumRows] = useState(20);
  const [activePage, setActivePage] = useState(1);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedEstateId, setSelectedEstateId] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const result = await statsScreenNewApi.get(
          `${
            process.env.REACT_APP_AOMS_AUTH_API
          }/inbound/stats?auctionHouseId=${auctionHouse.id}&startRowIndex=${
            (activePage - 1) * maximumRows
          }&maximumRows=${maximumRows}`
        );
        setData(result.data.result);
        setCount(result.data.count);
        setIsLoading(false);
      } catch (err) {
        console.err(err);
        setError(err.message);
        setIsLoading(false);
      }
    };

    getData();
    return () => {
      setData([]);
    };
  }, [auctionHouse]);

  // const Time = ({ time }) => {
  //   const [t] = useTimer(time);
  //   return t;
  // };

  const handlePagination = (newActivePage) => {
    setActivePage(newActivePage);
  };

  let paginationItems = [];
  if (data) {
    for (
      let noOfItems = count, pageNumber = 1;
      noOfItems > 0;
      noOfItems -= maximumRows, pageNumber++
    ) {
      paginationItems.push(
        <Pagination.Item
          key={pageNumber}
          active={pageNumber === activePage}
          onClick={() => {
            handlePagination(pageNumber);
          }}
          className="text-white font-weight-bold"
        >
          {pageNumber}
        </Pagination.Item>
      );
    }
  }

  const handleShowModal = (estateId) => {
    setShowModal(true);
    setSelectedEstateId(estateId);
  };
  return (
    <div>
      <InboundDetailsModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        estateId={selectedEstateId}
      />
      {isLoading ? (
        <Spinner animation="border" role="status" />
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : data.length === 0 ? (
        <Alert variant="danger">No data found</Alert>
      ) : (
        <Row>
          <Col xs={12}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>EstateNo</th>
                  <th>Vendor</th>
                  <th>Status</th>
                  <th>Location</th>
                  <th>BookedInDate</th>
                  <th>ArrivalDate</th>
                  <th>Pallets</th>
                  <th>UnloadingUser</th>
                  <th>UnloadingStaff</th>
                  <th>UnloadingStart</th>
                  <th>UnloadingEnd</th>
                  <th>SetOutUser</th>
                  <th>SetOutStaff</th>
                  <th>SetOutStart</th>
                  <th>SetOutEnd</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr
                    key={item.id}
                    onClick={() => handleShowModal(item.estateId)}
                    role="button"
                  >
                    <td>{item.estateNo}</td>
                    <td>{item.vendor}</td>
                    <td>{item.status}</td>
                    <td>{item.location}</td>
                    <td>{utcToLocaleDateTime(item.bookedInDate)}</td>
                    <td>{utcToLocaleDateTime(item.arrivalDate)}</td>
                    <td>{item.pallets}</td>
                    <td>{item.unloadingUser}</td>
                    <td>{item.unloadingStaff}</td>
                    <td>{utcToLocaleDateTime(item.unloadingStart)}</td>
                    <td>{utcToLocaleDateTime(item.unloadingEnd)}</td>
                    <td>{item.settingOutUser}</td>
                    <td>{item.settingOutStaff}</td>
                    <td>{utcToLocaleDateTime(item.settingOutStart)}</td>
                    <td>{utcToLocaleDateTime(item.settingOutEnd)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination className="d-flex flex-wrap justify-content-end">
              {activePage > 1 && (
                <Pagination.Prev
                  onClick={() => {
                    const previousPage = activePage - 1;
                    handlePagination(previousPage);
                  }}
                />
              )}
              {paginationItems}
              {activePage !== paginationItems.length && (
                <Pagination.Next
                  onClick={() => {
                    const nextPage = activePage + 1;
                    handlePagination(nextPage);
                  }}
                />
              )}
            </Pagination>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Inbounds;
