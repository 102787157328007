import React, { useState, useEffect } from "react";
import statsScreenNewApi from "../../API/statsScreenNewAPI";
import { Spinner, Table, Alert } from "react-bootstrap";
import { utcToLocaleDateTime } from "../../utils/timeConverter";

const TotalPickOrderLots = ({ auctionHouse }) => {
  const [pickOrderLots, setPickOrderLots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const getPickOrderLotsInformation = async () => {
      try {
        setIsLoading(true);
        const response = await statsScreenNewApi.get(
          `${process.env.REACT_APP_AOMS_AUTH_API}/auctionhouses/getTotalPicksOrder/${auctionHouse.id}`
        );

        setPickOrderLots(response.data.totalPicks);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMessage(err.message);
      }
    };

    getPickOrderLotsInformation();
  }, [auctionHouse]);

  return (
    <>
      {isLoading ? (
        <div className="d-flex flex-column my-3 justify-content-center align-items-center">
          <Spinner variant="primary" animation="border" role="status" />
          <p className="mb-0">Loading...</p>
        </div>
      ) : errorMessage ? (
        <Alert variant="danger">{`There has been an error: ${errorMessage}`}</Alert>
      ) : (
        <Table size="sm" bordered={true} responsive="sm" hover className="mt-3">
          <thead>
            <tr>
              <th>Location</th>
              <th>Total Picks</th>
              <th>Oldest Pick Order Lot Date</th>
            </tr>
          </thead>
          <tbody>
            {pickOrderLots
              ? pickOrderLots.map((el, i) => {
                  return (
                    <tr
                      key={el.name}
                      className={i === 0 ? "bg-danger text-white" : ""}
                    >
                      <td>{el.name}</td>
                      <td>{el.totalPickOrderLots}</td>
                      <td>{utcToLocaleDateTime(el.oldestDate)}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default TotalPickOrderLots;
