import React from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";

import { utcToLocaleDateTime } from "../../utils/timeConverter";

const PickerStats = ({ pickingInfo }) => {
  const picking = pickingInfo.pickerStats || [];

  return (
    <Container fluid>
      <Table size="sm" bordered={true} responsive="sm" hover className="mt-3">
        <thead>
          <tr>
            <th>Left To Process</th>
            <th>Processed Today</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{pickingInfo.totalLotsToProcess}</td>
            <td>{pickingInfo.processedToday}</td>
          </tr>
        </tbody>
      </Table>
      <Table size="sm" bordered={true} responsive="sm" hover className="mt-3">
        <thead>
          <tr>
            <th>Name</th>
            <th>Total Lots</th>
          </tr>
        </thead>
        <tbody>
          {picking.map((el) => {
            return (
              <tr key={el.name}>
                <td>{el.name}</td>
                <td>{el.totalLots}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <h2>Open Picking Jobs</h2>
      <Table size="sm" bordered={true} responsive="sm" hover className="mt-4">
        <thead>
          <tr>
            <th>Creation Date</th>
            <th>Picker</th>
            <th>Location</th>
            <th>Remaining Lots</th>
            <th>Total Lots</th>
          </tr>
        </thead>
        <tbody>
          {pickingInfo.openPickBatches.map((el) => {
            return (
              <tr key={el.id}>
                <td>{utcToLocaleDateTime(el.creationDate)}</td>
                <td>{el.user}</td>
                <td>{el.location}</td>
                <td>{el.remainingLots}</td>
                <td>{el.totalLots}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default PickerStats;
