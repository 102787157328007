import React, { useState, useEffect } from "react";
import statsScreenNewApi from "../../API/statsScreenNewAPI";
import { Table, Pagination, Row, Spinner, Alert } from "react-bootstrap";
import InvoiceDetailsModal from "./InvoiceDetailsModal";
import { getKeyByValue } from "../../utils/getKeyByValue";
import useTimer from "../../utils/useTimer";

const collectionPoint = Object.freeze({
  A: 0, // Collection for large items. Expected to be slow
  B: 1, // Collection for small items or one large item. Expected to be fast
  ISSUES: 2, // Lots that are not found or missing
});

const ActiveCollections = ({ auctionHouse }) => {
  const [collections, setCollections] = useState();
  const [activePage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [maximumRows, setMaximumRows] = useState(30);
  const [showModal, setShowModal] = useState(false);
  const [currentInvoiceId, setCurrentInvoiceId] = useState("");

  useEffect(() => {
    const getCollections = async () => {
      try {
        setIsLoading(true);
        const result = await statsScreenNewApi.get(
          `${
            process.env.REACT_APP_AOMS_AUTH_API
          }/collections/get-active-collections?startRowIndex=${
            (activePage - 1) * maximumRows
          }&maximumRows=${maximumRows}`
        );

        setCollections(result.data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMessage(err.message);
      }
    };
    getCollections();
  }, [auctionHouse, activePage, maximumRows]);

  const handlePagination = (newActivePage) => {
    setActivePage(newActivePage);
  };

  let paginationItems = [];
  if (collections) {
    for (
      let noOfItems = collections.count, pageNumber = 1;
      noOfItems > 0;
      noOfItems -= maximumRows, pageNumber++
    ) {
      paginationItems.push(
        <Pagination.Item
          key={pageNumber}
          active={pageNumber === activePage}
          onClick={() => {
            handlePagination(pageNumber);
          }}
          className="text-white font-weight-bold"
        >
          {pageNumber}
        </Pagination.Item>
      );
    }
  }

  const Time = ({ status, creationDate, pickingStart }) => {
    let time = creationDate;
    if (status === "Pending") {
      time = pickingStart;
    }

    const [t] = useTimer(time);
    return <td className="text-white">{!time ? "No data" : t}</td>;
  };

  const handleShowModal = (invoiceId) => {
    setCurrentInvoiceId(invoiceId);
    setShowModal(true);
  };

  return (
    <div>
      <InvoiceDetailsModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        invoiceId={currentInvoiceId}
      />
      {isLoading ? (
        <div className="d-flex flex-column my-3 justify-content-center align-items-center">
          <Spinner variant="primary" animation="border" role="status" />
          <p className="mb-0">Loading...</p>
        </div>
      ) : errorMessage ? (
        <Alert variant="danger">{`There has been an error: ${errorMessage}`}</Alert>
      ) : (
        <Row>
          <Table striped bordered hover responsive="sm">
            <thead>
              <tr>
                <th>InvoiceNo</th>
                <th>Customer</th>
                <th>Piker</th>
                <th>ColPoint</th>
                <th>Bay</th>
                <th>Lots</th>
                <th>Started</th>
              </tr>
            </thead>
            <tbody>
              {collections?.result?.map((el) => {
                return (
                  <tr
                    key={el.id}
                    className={`${
                      el.status === "Created"
                        ? "collectionStatusCreated"
                        : el.status === "Pending"
                        ? "collectionStatusPending"
                        : el.status === "Issues"
                        ? "collectionStatusIssues"
                        : "collectionStatusCompleted"
                    }`}
                    onClick={() => handleShowModal(el.invoiceId)}
                  >
                    <td className="text-white">{el.invoiceId}</td>
                    <td className="text-white">{el.customerName}</td>
                    <td className="text-white">{el.pickerName}</td>
                    <td className="text-white">
                      {getKeyByValue(collectionPoint, el.collectionPoint)}
                    </td>
                    <td className="text-white">{el.collectionBay}</td>
                    <td className="text-white">{el.totalLots}</td>
                    <Time
                      status={el.status}
                      creationDate={el.creationDate}
                      pickingStart={el.pickingStart}
                    />
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination className="d-flex flex-wrap justify-content-end">
            {activePage > 1 && (
              <Pagination.Prev
                onClick={() => {
                  const previousPage = activePage - 1;
                  handlePagination(previousPage);
                }}
              />
            )}
            {paginationItems}
            {activePage !== paginationItems.length && (
              <Pagination.Next
                onClick={() => {
                  const nextPage = activePage + 1;
                  handlePagination(nextPage);
                }}
              />
            )}
          </Pagination>
        </Row>
      )}
    </div>
  );
};

export default ActiveCollections;
