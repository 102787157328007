import React, { useState, useEffect } from "react";
import statsScreenNewApi from "../../API/statsScreenNewAPI";
import { Table, Row, Col, Modal, Form, Button } from "react-bootstrap";
import { getKeyByValue } from "../../utils/getKeyByValue";
import { utcToLocaleDateTime } from "../../utils/timeConverter";

const pickOrderLotsStatus = Object.freeze({
  PENDING: 0,
  PICKED: 1,
  ISSUES: 2,
});

const InvoiceDetailsModal = ({ show, handleClose, invoiceId }) => {
  const [data, setData] = useState([]);
  const [comment, setComment] = useState("");
  const [pickOrderLotId, setPickOrderLotId] = useState("");
  const [showCommentForm, setShowCommentForm] = useState(false);

  useEffect(() => {
    if (!invoiceId) return;
    const getInvoiceData = async () => {
      try {
        const result = await statsScreenNewApi.get(
          `${process.env.REACT_APP_AOMS_AUTH_API}/collections/invoice/${invoiceId}`
        );
        const comments = await statsScreenNewApi.get(
          `${process.env.REACT_APP_AOMS_AUTH_API}/pickorderlots/comments?invoiceId=${invoiceId}`
        );

        const data = result.data.result.map((item) => {
          const comment = comments.data.comments.find(
            (comment) => comment.pickOrderLotId === item.id
          );
          return {
            ...item,
            comment: comment ? comment.comment : "",
          };
        });

        setData({ data, dates: result.data.dates });
      } catch (err) {
        console.log(err);
      }
    };
    getInvoiceData();
  }, [invoiceId]);

  const submitComment = async () => {
    try {
      await statsScreenNewApi.put(
        `${process.env.REACT_APP_AOMS_AUTH_API}/pickorderlots/${pickOrderLotId}/comment`,
        {
          comment,
        }
      );
      const newData = { ...data };
      newData.data.map((item) =>
        item.id === pickOrderLotId ? (item.comment = comment) : item
      );
      setData(newData);

      setShowCommentForm(false);
    } catch (err) {
      console.log(err);
    }
  };

  console.log(data);

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      {data?.dates?.length && (
        <Modal.Header className="d-flex flex-column">
          <Row className="flex-row w-100">
            <Col xs={12} md={6} className="h5">
              InvoiceNo: {invoiceId}
            </Col>
            <Col xs={12} md={6} className="h5">
              InvoiceDate: {utcToLocaleDateTime(data.dates[0].invoiceDate)}
            </Col>
          </Row>
          <Row className="flex-row w-100 mt-2">
            <Col xs={12} md={6} className="h5">
              ColDeadline:
              {utcToLocaleDateTime(data.dates[0].collectionDeadline)}
            </Col>
            <Col xs={12} md={6} className="h5">
              AuctionEndDate: {utcToLocaleDateTime(data.dates[0].endDate)}
            </Col>
          </Row>
          {showCommentForm && (
            <Row className="flex-row w-100 mt-2">
              <Form>
                <Form.Label>Add Comment:</Form.Label>
                <Form.Group controlId="addComment" className="d-flex">
                  <Form.Control
                    type="text"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Please enter lot comment..."
                  />
                  <Button
                    className="btn btn-primary ms-2"
                    onClick={submitComment}
                    disabled={!comment}
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Form>
            </Row>
          )}
        </Modal.Header>
      )}
      <Modal.Body>
        {data?.data?.length && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Image</th>
                <th>StockId</th>
                <th>LotNo</th>
                <th>Title</th>
                <th>Container</th>
                <th>Status</th>
                <th>Collected</th>
                <th>PickIssue</th>
                <th>Date</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((lot) => (
                <tr key={lot.id}>
                  <td>
                    <a
                      href={`https://scwebv7cdn.azureedge.net/thumbnails/${lot.images[0]}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={`https://scwebv7cdn.azureedge.net/thumbnails/${lot.images[0]}`}
                        alt={lot.productTitle}
                        style={{ width: "100px", height: "100px" }}
                      />
                    </a>
                  </td>
                  <td>{lot.stockId}</td>
                  <td>{lot.lotNo}</td>
                  <td>{lot.productTitle}</td>
                  <td>{lot.containerName}</td>
                  <td>{getKeyByValue(pickOrderLotsStatus, lot.status)}</td>
                  <td>{lot.stockStatus === 0 ? "No" : "Yes"}</td>
                  <td>{lot.pickIssue}</td>
                  <td>
                    {lot.modificationDate
                      ? utcToLocaleDateTime(lot.modificationDate)
                      : ""}
                  </td>
                  <td>{lot.comment}</td>
                  <td>
                    <span
                      className="text-decoration-underline text-primary"
                      role="button"
                      onClick={() => {
                        setShowCommentForm(true);
                        setPickOrderLotId(lot.id);
                      }}
                    >
                      AddComment
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default InvoiceDetailsModal;
