import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Box,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  TableBody,
  makeStyles,
} from "@material-ui/core";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { millisToReadableTime, utcToLocaleTime } from "../utils/timeConverter";
import axios from "axios";

const useStyles = makeStyles(() => ({
  dropDown: {
    minWidth: 120,
  },
  textRed: {
    color: "#f50057",
  },
  textGreen: {
    color: "#445A3E",
  },
}));

const Dispatch = ({ history, location: { state } }) => {
  const classes = useStyles();

  const [currentAuctionHouse, setCurrentAuctionHouse] = useState(
    sessionStorage.getItem("SELECTED_AUCTIONHOUSE")
      ? JSON.parse(sessionStorage.getItem("SELECTED_AUCTIONHOUSE"))
      : state[0]
  );
  const [dispatchingStats, setDispatchingStats] = useState([]);
  const [timeInMillisNow, setTimeInMillisNow] = useState(Date.now());

  useEffect(() => {
    let counter = 0;

    getDispatchingStats();

    const id = setInterval(() => {
      counter += 1;
      if (counter === 30) {
        getDispatchingStats();
        counter = 0;
      }
      setTimeInMillisNow(Date.now());
    }, 1000);

    function getDispatchingStats() {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/auctionhouses/${currentAuctionHouse.id}/stats/dispatch`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("ACCESS_TOKEN")}`,
            },
          }
        )
        .then((res) => {
          setDispatchingStats(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (err.response.status === 401) logout();
        });
    }

    function logout() {
      sessionStorage.removeItem("ACCESS_TOKEN");
      history.replace("/");
    }

    return () => clearInterval(id);
  }, [currentAuctionHouse, history]);

  function handleAuctionHouseChange(e) {
    const value = e.target.value;
    const selectedAuctionnHouse = state.filter(
      (auctionHouse) => auctionHouse.name === value
    )[0];

    sessionStorage.setItem(
      "SELECTED_AUCTIONHOUSE",
      JSON.stringify(selectedAuctionnHouse)
    );
    setCurrentAuctionHouse(selectedAuctionnHouse);
  }

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const fullscreenHandle = useFullScreenHandle();

  return (
    <>
      <Grid container>
        {!isMobile && (
          <Grid container item justify="flex-end" xs={6}>
            <Button variant="outlined" onClick={fullscreenHandle.enter}>
              Enter fullscreen
            </Button>
          </Grid>
        )}
        <Grid
          container
          item
          justify={isMobile ? "center" : "flex-start"}
          xs={isMobile ? 12 : 6}
        >
          <FormControl variant="filled">
            <InputLabel htmlFor="auction-house">Auction House</InputLabel>
            <Select
              native
              value={currentAuctionHouse.name}
              onChange={handleAuctionHouseChange}
              inputProps={{
                name: "auctionHouse",
                id: "auction-house",
              }}
            >
              {state.map((auctionHouse) => {
                return (
                  <option key={auctionHouse.id} value={auctionHouse.name}>
                    {auctionHouse.name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <FullScreen handle={fullscreenHandle}>
        {dispatchingStats.length !== 0 ? (
          <Container maxWidth="xl">
            <Box mt={3}>
              <Grid container spacing={8}>
                <Grid container item spacing={3}>
                  <Grid container item justify="center" sm={6}>
                    <Typography variant={isMobile ? "h2" : "h1"}>
                      {"To Process:"}
                    </Typography>
                    <Typography
                      variant={isMobile ? "h2" : "h1"}
                      color="secondary"
                    >
                      {`\u00A0${
                        dispatchingStats.pending +
                        dispatchingStats.picked +
                        dispatchingStats.processing
                      }`}
                    </Typography>
                  </Grid>
                  <Grid container item justify="center" sm={6}>
                    <Typography
                      variant={isMobile ? "h2" : "h1"}
                    >{`Complete:`}</Typography>
                    <Typography
                      variant={isMobile ? "h2" : "h1"}
                      color="primary"
                    >
                      {`\u00A0${dispatchingStats.processedToday}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item spacing={4}>
                  <Grid container item sm={6}>
                    {dispatchingStats.openDispatchJobs.length !== 0 ? (
                      <>
                        <Typography variant="h2">Open Dispatch Jobs</Typography>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Dispatcher</TableCell>
                                <TableCell>Time Open</TableCell>
                                <TableCell>Total Lots</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {dispatchingStats.openDispatchJobs.map(
                                (openDispatchJob) => {
                                  return (
                                    <TableRow key={openDispatchJob.name}>
                                      <TableCell>
                                        {openDispatchJob.name}
                                      </TableCell>
                                      <TableCell>
                                        {openDispatchJob.startProcessDate
                                          ? millisToReadableTime(
                                              timeInMillisNow -
                                                new Date(
                                                  openDispatchJob.startProcessDate
                                                )
                                            )
                                          : "-"}
                                      </TableCell>
                                      <TableCell>
                                        {openDispatchJob.totalLots}
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    ) : (
                      <Typography variant="h2">
                        No Open Dispatch Jobs
                      </Typography>
                    )}
                  </Grid>
                  <Grid container item sm={6}>
                    {dispatchingStats.dispatchStats ? (
                      <>
                        <Typography variant="h2">Dispatch Scores</Typography>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Last Completed</TableCell>
                                <TableCell>Total Lots</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {dispatchingStats.dispatchStats.map(
                                (dispatchStat) => {
                                  return (
                                    <TableRow key={dispatchStat.name}>
                                      <TableCell>{dispatchStat.name}</TableCell>
                                      <TableCell>
                                        {utcToLocaleTime(
                                          dispatchStat.lastProcessed
                                        )}
                                      </TableCell>
                                      <TableCell className={classes.textGreen}>
                                        {dispatchStat.totalLots}
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    ) : (
                      <Typography variant="h2">
                        Nothing Dispatched Today
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Container>
        ) : (
          <CircularProgress />
        )}
      </FullScreen>
    </>
  );
};

export default Dispatch;
