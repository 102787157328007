import React from "react";
import { utcToLocaleDateTime } from "../../utils/timeConverter";
import { CheckOutlined } from "@ant-design/icons";

const OldestPickLot2 = ({ lots }) => {
  return (
    <tr>
      <td>{lots.location}</td>
      <td>{lots.container}</td>
      <td>{lots.lotNo}</td>
      <td>{lots.productTitle}</td>
      <td>{lots.pickOrderLotStatus > 0 ? <CheckOutlined /> : null}</td>
      <td>{utcToLocaleDateTime(lots.creationDate)}</td>
      <td>{lots.pickOrderId}</td>
    </tr>
  );
};

export default OldestPickLot2;
