import React, { useState, useEffect } from "react";

import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import OldestPendingOrder from "../components/Picking2Comp/OldestPendingOrder";
import PickingStats from "../components/Picking2Comp/PickingStats";
import DispatchStats from "../components/Picking2Comp/DispatchStats";
import TotalPickOrderLots from "../components/Picking2Comp/TotalPickOrderLots";
import TotalPickIssues from "../components/Picking2Comp/TotalPickIssues";
import TotalLotsEnding from "../components/Picking2Comp/TotalLotsEnding";
import UpcomingInboundDev from "../components/Picking2Comp/UpcomingInboundDev";
import PickingWorkflow from "../components/Picking2Comp/PickingWorkflow";
import { logout } from "../utils/logout";
import statsScreenOldAPI from "../API/statsScreenOldAPI";

const Picking2 = ({ history, location: { state } }) => {
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState(
    sessionStorage.getItem("SELECTED_AUCTIONHOUSE")
      ? JSON.parse(sessionStorage.getItem("SELECTED_AUCTIONHOUSE"))
      : state[0]
  );

  const [navOptions, setNavOptions] = useState();
  const [pickingInfo, setPickingInfo] = useState([]);
  const [dispatchInfo, setDispatchInfo] = useState([]);

  useEffect(() => {
    const getPickingInformation = async () => {
      try {
        const response = await statsScreenOldAPI.get(
          `${process.env.REACT_APP_BASE_URL}/api/auctionhouses/${currentAuctionHouse.id}/stats/picker`
        );
        setPickingInfo(response.data);
      } catch (err) {
        console.log(err);
        logout(history);
      }
    };
    const getDispatchInformation = async () => {
      try {
        const response = await statsScreenOldAPI.get(
          `${process.env.REACT_APP_BASE_URL}/api/auctionhouses/${currentAuctionHouse.id}/stats/dispatch`
        );

        setDispatchInfo(response.data);
      } catch (err) {
        console.log(err);
        logout(history);
      }
    };

    getPickingInformation();
    getDispatchInformation();
  }, [currentAuctionHouse, history]);

  //Set Menu name at the page initiation
  useEffect(() => {
    setNavOptions("oldestPendingOrder");
  }, []);

  const handleSelectAuctionHouse = (event) => {
    const selectedAuctionHouse = state.filter(
      (el) => el.name === event.target.value
    )[0];
    setCurrentAuctionHouse(selectedAuctionHouse);
    sessionStorage.setItem(
      "SELECTED_AUCTIONHOUSE",
      JSON.stringify(selectedAuctionHouse)
    );
  };

  return (
    <div className="picking2-container">
      <Container fluid>
        <Form onSubmit={() => console.log("Form submit")}>
          <Form.Group as={Row}>
            <Form.Label column="2">Auction House: </Form.Label>
            <Col sm="10">
              <Form.Control
                as="select"
                value={currentAuctionHouse.name}
                onChange={(event) => {
                  handleSelectAuctionHouse(event);
                }}
              >
                {state.map((el) => {
                  return (
                    <option key={el.id} value={el.name}>
                      {el.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          </Form.Group>
        </Form>
      </Container>

      <Container fluid className="mt-3">
        <Nav fill variant="tabs" defaultActiveKey="oldestPendingOrder">
          <Nav.Item onClick={() => setNavOptions("oldestPendingOrder")}>
            <Nav.Link eventKey="oldestPendingOrder">
              Oldest Pending Orders
            </Nav.Link>
          </Nav.Item>
          <Nav.Item onClick={() => setNavOptions("pickingStats")}>
            <Nav.Link eventKey="pickingStats">Picking Stats</Nav.Link>
          </Nav.Item>
          <Nav.Item onClick={() => setNavOptions("dispatchStats")}>
            <Nav.Link eventKey="dispatchStats">Dispatch Stats</Nav.Link>
          </Nav.Item>
          <Nav.Item onClick={() => setNavOptions("totalPickOrderLots")}>
            <Nav.Link eventKey="totalPickOrderLots">
              Pending Pick Order Lots
            </Nav.Link>
          </Nav.Item>
          <Nav.Item onClick={() => setNavOptions("pickingWorkflow")}>
            <Nav.Link eventKey="pickingWorkflow">Picking Workflow</Nav.Link>
          </Nav.Item>
          <Nav.Item onClick={() => setNavOptions("totalPickIssues")}>
            <Nav.Link eventKey="totalPickIssues">Pick Issues</Nav.Link>
          </Nav.Item>
          <Nav.Item onClick={() => setNavOptions("totalLotsEnding")}>
            <Nav.Link eventKey="totalLotsEnding">Lots Ending</Nav.Link>
          </Nav.Item>
          <Nav.Item onClick={() => setNavOptions("upcomingInboundDev")}>
            <Nav.Link eventKey="upcomingInboundDev">
              Upcoming Inbound Deliveries
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Container>

      <Container fluid>
        {navOptions === "oldestPendingOrder" && (
          <OldestPendingOrder pickingInfo={pickingInfo} />
        )}
        {navOptions === "pickingStats" && (
          <PickingStats pickingInfo={pickingInfo} />
        )}
        {navOptions === "dispatchStats" && (
          <DispatchStats dispatchInfo={dispatchInfo} />
        )}
        {navOptions === "totalPickOrderLots" && (
          <TotalPickOrderLots auctionHouse={currentAuctionHouse} />
        )}
        {navOptions === "pickingWorkflow" && (
          <PickingWorkflow auctionHouse={currentAuctionHouse} />
        )}
        {navOptions === "totalPickIssues" && (
          <TotalPickIssues auctionHouse={currentAuctionHouse} />
        )}
        {navOptions === "totalLotsEnding" && (
          <TotalLotsEnding auctionHouse={currentAuctionHouse} />
        )}
        {navOptions === "upcomingInboundDev" && (
          <UpcomingInboundDev auctionHouse={currentAuctionHouse} />
        )}
      </Container>
    </div>
  );
};

export default Picking2;
