import React, { useState } from "react";
import {
  Button,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import Cookies from "universal-cookie/es6";
import { getDecodedAccessToken } from "../utils/Tokens";

const useStyles = makeStyles(() => ({
  page: {
    minHeight: "100vh",
  },
}));

const Login = (props) => {
  console.log(process.env);
  console.log(process.env.NODE_ENV);
  console.log(process.env.REACT_APP_BASE_URL);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const classes = useStyles();

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const url = `${process.env.REACT_APP_BASE_URL}/token`;
    const data = `grant_type=password&username=${email}&password=${encodeURIComponent(
      password
    )}`;

    axios
      .post(`${process.env.REACT_APP_AUTH_API_URL}/login`, {
        email: email,
        password: password,
      })
      .then((res) => {
        sessionStorage.setItem("ACCESS_AUTH_TOKEN", res.data.accessToken);

        // Check if user has auth permissions to enter admin
        const decodedToken = getDecodedAccessToken();
        let permissions = [
          ...decodedToken.permissions.applications,
          ...decodedToken.permissions.roles,
        ];
        if (!permissions.includes("auth")) {
          sessionStorage.removeItem("ACCESS_AUTH_TOKEN");
          console.log("User does not have access");
        }

        localStorage.setItem("AUTH_TOKEN", res.data.refreshToken);
        const cookies = new Cookies();
        const expiryDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * 90); // 90 days from now
        cookies.set("authRefreshToken", res.data.refreshToken, {
          path: "/",
          expires: expiryDate,
          secure: process.env.NODE_ENV === "production",
        });
        props.history.replace("/home");
      })
      .catch((err) => {
        console.error(err);
        setErrorMessage("Incorrect email or password");
      });

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: data,
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw new Error(res.error);
        }
      })
      .then((data) => {
        sessionStorage.setItem("ACCESS_TOKEN", data.access_token);
        props.history.replace("/home");
      })
      .catch((err) => {
        console.error(err);
        setErrorMessage("Incorrect email or password");
      });
  };

  return (
    <Container maxWidth="xs">
      <Grid
        className={classes.page}
        container
        alignItems="center"
        justify="center"
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid container item>
              <Typography variant="h4">Login</Typography>
            </Grid>
            {errorMessage && (
              <Grid container item>
                <Typography>{errorMessage}</Typography>
              </Grid>
            )}
            <Grid container item>
              <TextField
                label="Email"
                type="email"
                name="email"
                variant="filled"
                value={email}
                fullWidth
                required
                onChange={handleInput}
                error={errorMessage !== ""}
              />
            </Grid>
            <Grid container item>
              <TextField
                label="Password"
                type="password"
                name="password"
                variant="filled"
                value={password}
                fullWidth
                required
                onChange={handleInput}
                error={errorMessage !== ""}
              />
            </Grid>
            <Grid container item>
              <Button
                type="submit"
                variant="outlined"
                fullWidth
                disabled={email === "" || password === ""}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Container>
  );
};

export default Login;
