import React, { useState, useEffect } from "react";
import { Table, Spinner, Alert } from "react-bootstrap";
import { utcToLocaleDateTime } from "../../utils/timeConverter";
import statsScreenNewApi from "../../API/statsScreenNewAPI";

const TotalLotsEnding = ({ auctionHouse }) => {
  const [totalLots, setTotalLots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const getTotalLots = async () => {
      try {
        setIsLoading(true);
        const response = await statsScreenNewApi.get(
          `${process.env.REACT_APP_AOMS_AUTH_API}/auctionhouses/getTotalLotsEnding/${auctionHouse.id}`
        );

        setTotalLots(response.data.totalLots);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMessage(err.message);
      }
    };
    getTotalLots();
  }, [auctionHouse]);

  return (
    <>
      {isLoading ? (
        <div className="d-flex flex-column my-3 justify-content-center align-items-center">
          <Spinner variant="primary" animation="border" role="status" />
          <p className="mb-0">Loading...</p>
        </div>
      ) : errorMessage ? (
        <Alert variant="danger">{`There has been an error: ${errorMessage}`}</Alert>
      ) : (
        <Table size="sm" bordered={true} responsive="sm" hover className="mt-3">
          <thead>
            <tr>
              <th>Name</th>
              <th>Lots</th>
              <th>End Date</th>
              <th>Colection Deadline</th>
            </tr>
          </thead>
          <tbody>
            {totalLots
              ? totalLots.map((el) => {
                  return (
                    <tr key={el.id}>
                      <td>{el.title}</td>
                      <td>{el.totalLots}</td>
                      <td>{utcToLocaleDateTime(el.endDate)}</td>
                      <td>{utcToLocaleDateTime(el.collectionDeadline)}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default TotalLotsEnding;
