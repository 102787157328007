import React from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";

import { utcToLocaleDateTime } from "../../utils/timeConverter";

const DispatchStats = ({ dispatchInfo }) => {
  const dispatch = dispatchInfo.dispatchStats || [];

  return (
    <Container fluid>
      <Table size="sm" bordered={true} responsive="sm" hover className="mt-3">
        <thead>
          <tr>
            <th>Pending</th>
            <th>Picked</th>
            <th>Processed</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{dispatchInfo.pending}</td>
            <td>{dispatchInfo.picked}</td>
            <td>{dispatchInfo.processedToday}</td>
          </tr>
        </tbody>
      </Table>
      <Table size="sm" bordered={true} responsive="sm" hover className="mt-3">
        <thead>
          <tr>
            <th>Name</th>
            <th>Last Complete</th>
            <th>Total Lots</th>
          </tr>
        </thead>
        <tbody>
          {dispatch.map((el) => {
            return (
              <tr key={el.name}>
                <td>{el.name}</td>
                <td>{utcToLocaleDateTime(el.lastProcessed).split(",")[1]}</td>
                <td>{el.totalLots}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
          <h2>Open Dispatch Jobs</h2>
      <Table size="sm" bordered={true} responsive="sm" hover className="mt-4">
        <thead>
          <tr>
            <th>Start Process Date</th>
            <th>Name</th>
            <th>Total Lots</th>
          </tr>
        </thead>
        <tbody>
          {dispatchInfo.openDispatchJobs.map((el) => {
            return (
              <tr key={el.name}>
                <td>{utcToLocaleDateTime(el.startProcessDate)}</td>
                <td>{el.name}</td>
                <td>{el.totalLots}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default DispatchStats;
