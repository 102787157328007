import React, { useState, useEffect } from "react";
import { Spinner, Table, Alert } from "react-bootstrap";
import { utcToLocaleDateTime } from "../../utils/timeConverter";
import statsScreenNewApi from "../../API/statsScreenNewAPI";

const TotalPickIssues = ({ auctionHouse }) => {
  const [pickIssues, setPickIssues] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const getPickIssues = async () => {
      try {
        const response = await statsScreenNewApi.get(
          `${process.env.REACT_APP_AOMS_AUTH_API}/auctionhouses/${auctionHouse.id}/pick-issues`
        );

        setPickIssues(response.data);
        setErrorMessage(false);
        setIsLoading("");
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMessage(err.message);
      }
    };
    if (auctionHouse) {
      getPickIssues();
    }
  }, [auctionHouse]);

  return (
    <>
      {isLoading ? (
        <div className="d-flex flex-column my-3 justify-content-center align-items-center">
          <Spinner variant="primary" animation="border" role="status" />
          <p className="mb-0">Loading...</p>
        </div>
      ) : errorMessage ? (
        <Alert variant="danger">{`There has been an error: ${errorMessage}`}</Alert>
      ) : (
        <>
          {pickIssues?.length > 0 && (
            <h2>{`Total issues: ${pickIssues.length}`}</h2>
          )}
          <Table
            size="sm"
            bordered={true}
            responsive="sm"
            hover
            className="mt-3"
          >
            <thead>
              <tr>
                <th>Location</th>
                <th>Container</th>
                <th>Lot No</th>
                <th>Product</th>
                <th>Pick Order Lot Creation Date</th>
              </tr>
            </thead>
            <tbody>
              {pickIssues?.map((el) => {
                return (
                  <tr key={el.pickOrderLotId}>
                    <td>{el.location}</td>
                    <td>{el.container}</td>
                    <td>{el.lotNo}</td>
                    <td>{el.productTitle}</td>
                    <td>{utcToLocaleDateTime(el.pickOrderLotCreationDate)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default TotalPickIssues;
