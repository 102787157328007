export function utcToLocaleDateTime(timeInUTC) {
  return new Date(timeInUTC).toLocaleString("en-GB", {
    timeZone: "Europe/London",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function utcToLocaleTime(timeInUTC) {
  return new Date(timeInUTC).toLocaleString("en-GB", {
    timeZone: "Europe/London",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function millisToReadableTime(millis) {
  let hours = Math.floor(millis / 3600000);
  if (!hours) {
    hours = "";
  } else {
    hours += ":";
  }
  const minutes = Math.floor((millis % 3600000) / 60000);
  const seconds = Number(((millis % 60000) / 1000).toFixed(0));
  return seconds === 60
    ? `${hours}${minutes + 1}:00`
    : `${hours}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
}
