import { ThemeProvider } from "@material-ui/core";
import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import Dispatch from "../../pages/Dispatch";
import Home from "../../pages/Home";
import Login from "../../pages/Login";
import Lotting from "../../pages/Lotting";
import Picking from "../../pages/Picking";
import Picking2 from "../../pages/Picking2";
import Returns from "../../pages/Returns";
import Qms from "../../pages/Qms";
import Collections from "../../pages/Collections";
import Inbound from "../../pages/Inbound";
import theme from "../../theme/theme";
import PrivateRoute from "../Routes/PrivateRoute";
import PublicRoute from "../Routes/PublicRoute";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { getRefreshToken } from "../../utils/Tokens";

import "bootstrap/dist/css/bootstrap.min.css";

// Set axios interceptors
const refreshAuthLogic = (failedRequest) => {
  return axios
    .get(`${process.env.REACT_APP_AUTH_API_URL}/token/${getRefreshToken()}`)
    .then((res) => {
      const accessToken = res.data.accessToken;
      sessionStorage.setItem("ACCESS_AUTH_TOKEN", accessToken);
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + accessToken;
      return Promise.resolve();
    })
    .catch((err) => Promise.reject(err));
};

createAuthRefreshInterceptor(axios, refreshAuthLogic);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <PublicRoute restricted={true} exact path="/" component={Login} />
          <PrivateRoute restricted={true} exact path="/home" component={Home} />
          <PrivateRoute
            restricted={true}
            exact
            path="/dispatch"
            component={Dispatch}
          />
          <PrivateRoute
            restricted={true}
            exact
            path="/picking"
            component={Picking}
          />
          <PrivateRoute
            restricted={true}
            exact
            path="/statsScreen"
            component={Picking2}
          />
          <PrivateRoute
            restricted={true}
            exact
            path="/lotting"
            component={Lotting}
          />
          <PrivateRoute
            restricted={true}
            exact
            path="/returns"
            component={Returns}
          />
          <PrivateRoute restricted={true} exact path="/qms" component={Qms} />
          <PrivateRoute
            restricted={true}
            exact
            path="/collections"
            component={Collections}
          />
          <PrivateRoute
            restricted={true}
            exact
            path="/inbound"
            component={Inbound}
          />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
