import React, { useState } from "react";
import { Container, Row, Col, Form, Nav } from "react-bootstrap";
import ActiveCollections from "../components/Collections/ActiveCollections";
import AllCollections from "../components/Collections/AllCollections";
import CollectionsIssues from "../components/Collections/CollectionsIssues";

const Collections = ({ history, location: { state } }) => {
  const [currentAuctionHouse, setCurrentAuctionHouse] = useState(
    sessionStorage.getItem("SELECTED_AUCTIONHOUSE")
      ? JSON.parse(sessionStorage.getItem("SELECTED_AUCTIONHOUSE"))
      : state[0]
  );
  const [navOptions, setNavOptions] = useState("activeCollections");

  const handleSelectAuctionHouse = (e) => {
    const selectedAuctionHouse = state.filter(
      (el) => el.name === e.target.value
    );
    setCurrentAuctionHouse(selectedAuctionHouse[0]);
    sessionStorage.setItem(
      "SELECTED_AUCTIONHOUSE",
      JSON.stringify(selectedAuctionHouse)
    );
  };

  return (
    <Container as="main" fluid>
      <Row>
        <Col xs={12} className="mt-2">
          <Form.Select
            aria-label="Select Auction House"
            onChange={handleSelectAuctionHouse}
          >
            {state.map((auctionHouse) => (
              <option key={auctionHouse.id} value={auctionHouse.name}>
                {auctionHouse.name}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mt-3">
          <Nav fill variant="tabs" defaultActiveKey="activeCollections">
            <Nav.Item>
              <Nav.Link
                eventKey="activeCollections"
                onClick={() => setNavOptions("activeCollections")}
              >
                Active Collections
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="allCollections"
                onClick={() => setNavOptions("allCollections")}
              >
                All Collections
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="collectionsIssues"
                onClick={() => setNavOptions("collectionsIssues")}
              >
                Collections Issues
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12}>
          {navOptions === "activeCollections" && (
            <ActiveCollections auctionHouse={currentAuctionHouse} />
          )}
          {navOptions === "allCollections" && (
            <AllCollections auctionHouse={currentAuctionHouse} />
          )}
          {navOptions === "collectionsIssues" && (
            <CollectionsIssues auctionHouse={currentAuctionHouse} />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Collections;
