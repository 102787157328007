import { Grid, useMediaQuery } from '@material-ui/core';
import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const Qms = ({ history }) => {
    const [agents, setAgents] = useState([]);
    const [depts, setDepts] = useState([]);
    const [openTickets, setOpenTickets] = useState("");
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    

    useEffect(() => {
        function getQmsStats() {
            axios.get(`${process.env.REACT_APP_QMS_API_URL}/stats`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("ACCESS_AUTH_TOKEN")}`
                }
            }).then((res) => {
                console.log(res.data);
                async function handleA(agents) {
                    await handleAgents(agents);
                }
                handleA(res.data.agents);
                setDepts(res.data.roles);
                setOpenTickets(res.data.open);
    
                //update the stats
            }).catch((err) => {
                if (err.response.status === 404) console.error("No Results");
                else if (err.response.status === 401) logout();
                //update the stats
            })
        }

        function logout() {
            sessionStorage.removeItem("ACCESS_TOKEN");
            history.replace("/");
        }
    
        async function handleAgents(agents) {
            for (const agent of agents) {
                if (agent.assignedAgentId != null) {
                    const user = (await axios.get(`${process.env.REACT_APP_AUTH_API_URL}/users/${agent.assignedAgentId}`, {
                        headers: {
                            Authorization: `Bearer ${sessionStorage.getItem("ACCESS_AUTH_TOKEN")}`
                        }
                    })).data;
                    console.log(user);
                    agent.name = `${user.firstName} ${user.lastName}`;
                } else {
                    agent.name = "No Agent";
                }
            }
            console.log(agents);
            setAgents(agents);
        }
        getQmsStats();
    }, [history]);

    

    return (
        <Grid container>
            <Grid container item justify={"center"}
                xs={12}>
                <h1>Qms</h1>
            </Grid>
            <Grid container item justify={"center"}
                xs={12}>
                <h1>Open Tickets: <span style={{ color: '#F50013' }}>{openTickets}</span></h1>
            </Grid>
            <Grid container item justify="center"
                xs={isMobile ? 12 : 6}>
                <h1>Agents</h1>
                {agents.map(agent => {
                    return (
                        <Grid container item justify="center"><h2 key={agent.assignedAgentId}>{agent.name}: {agent.totalTickets}</h2></Grid>
                    )
                })}
            </Grid>
            <Grid container item justify="center"
                xs={isMobile ? 12 : 6}>
                <h1>Departments</h1>
                {depts.map(dept => {
                    return (
                        <Grid container item justify="center"><h2 key={dept.assignedRole}>{dept.assignedRole}: {dept.totalTickets}</h2></Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}

export default Qms;