import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Box,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  TableBody,
  makeStyles,
} from "@material-ui/core";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { millisToReadableTime } from "../utils/timeConverter";
import axios from "axios";

const useStyles = makeStyles(() => ({
  dropDown: {
    minWidth: 120,
  },
  textRed: {
    color: "#f50057",
  },
  textGreen: {
    color: "#445A3E",
  },
}));

const Lotting = ({ history, location: { state } }) => {
  const classes = useStyles();

  const [currentAuctionHouse, setCurrentAuctionHouse] = useState(
    sessionStorage.getItem("SELECTED_AUCTIONHOUSE")
      ? JSON.parse(sessionStorage.getItem("SELECTED_AUCTIONHOUSE"))
      : state[0]
  );
  const [lottingStats, setLottingStats] = useState([]);
  const [timeInMillisNow, setTimeInMillisNow] = useState(Date.now());

  useEffect(() => {
    let counter = 0;

    getLottingStats();

    const id = setInterval(() => {
      counter += 1;
      if (counter === 30) {
        getLottingStats();
        counter = 0;
      }
      setTimeInMillisNow(Date.now());
    }, 1000);

    function getLottingStats() {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/auctionhouses/${currentAuctionHouse.id}/stats/lotter`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("ACCESS_TOKEN")}`,
            },
          }
        )
        .then((res) => {
          setLottingStats(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (err.response.status === 401) logout();
        });
    }

    function logout() {
      sessionStorage.removeItem("ACCESS_TOKEN");
      history.replace("/");
    }

    return () => clearInterval(id);
  }, [currentAuctionHouse, history]);

  function handleAuctionHouseChange(e) {
    const value = e.target.value;
    const selectedAuctionnHouse = state.filter(
      (auctionHouse) => auctionHouse.name === value
    )[0];

    sessionStorage.setItem(
      "SELECTED_AUCTIONHOUSE",
      JSON.stringify(selectedAuctionnHouse)
    );
    setCurrentAuctionHouse(selectedAuctionnHouse);
  }

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const fullscreenHandle = useFullScreenHandle();

  return (
    <>
      <Grid container>
        {!isMobile && (
          <Grid container item justify="flex-end" xs={6}>
            <Button variant="outlined" onClick={fullscreenHandle.enter}>
              Enter fullscreen
            </Button>
          </Grid>
        )}
        <Grid
          container
          item
          justify={isMobile ? "center" : "flex-start"}
          xs={isMobile ? 12 : 6}
        >
          <FormControl variant="filled">
            <InputLabel htmlFor="auction-house">Auction House</InputLabel>
            <Select
              native
              value={currentAuctionHouse.name}
              onChange={handleAuctionHouseChange}
              inputProps={{
                name: "auctionHouse",
                id: "auction-house",
              }}
            >
              {state.map((auctionHouse) => {
                return (
                  <option key={auctionHouse.id} value={auctionHouse.name}>
                    {auctionHouse.name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <FullScreen handle={fullscreenHandle}>
        {lottingStats.length !== 0 ? (
          <Container maxWidth="xl">
            <Box mt={3}>
              <Grid container spacing={8}>
                <Grid container item justify="center">
                  <Typography
                    variant={isMobile ? "h2" : "h1"}
                  >{`Created:`}</Typography>
                  <Typography variant={isMobile ? "h2" : "h1"} color="primary">
                    {`\u00A0${lottingStats.totalLots}`}
                  </Typography>
                </Grid>
                <Grid container item spacing={4}>
                  <Grid container item>
                    {lottingStats.lotterStats.length !== 0 ? (
                      <>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Lotter</TableCell>
                                <TableCell>Time Since Last Created</TableCell>
                                <TableCell>Created Lots</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {lottingStats.lotterStats.map((lotterStat) => {
                                return (
                                  <TableRow key={lotterStat.name}>
                                    <TableCell>{lotterStat.name}</TableCell>
                                    <TableCell
                                      className={
                                        timeInMillisNow -
                                          new Date(lotterStat.lastCreated) >
                                        2 * 60 * 1000 // 2 minutes
                                          ? classes.textRed
                                          : ""
                                      }
                                    >
                                      {millisToReadableTime(
                                        timeInMillisNow -
                                          new Date(lotterStat.lastCreated)
                                      )}
                                    </TableCell>
                                    <TableCell className={classes.textGreen}>
                                      {lotterStat.totalLots}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    ) : (
                      <Typography variant="h2">Nothing Lotted Today</Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Container>
        ) : (
          <CircularProgress />
        )}
      </FullScreen>
    </>
  );
};

export default Lotting;
