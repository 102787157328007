import React, { useState, useEffect } from "react";
import { Table, Spinner, Alert } from "react-bootstrap";
import { utcToLocaleDateTime } from "../../utils/timeConverter";
import statsScreenNewApi from "../../API/statsScreenNewAPI";

const UpcomingInboundDev = ({ auctionHouse }) => {
  const [inboundDeliveries, setInboundDeliveries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const getInboundDeliveries = async () => {
      try {
        setIsLoading(true);
        const response = await statsScreenNewApi.get(
          `${process.env.REACT_APP_AOMS_AUTH_API}/auctionhouses/getInboundDeliveries/${auctionHouse.id}`
        );

        setInboundDeliveries(response.data.inboundDeliveries);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMessage(err.message);
      }
    };

    getInboundDeliveries();
  }, [auctionHouse]);

  return (
    <>
      {isLoading ? (
        <div className="d-flex flex-column my-3 justify-content-center align-items-center">
          <Spinner variant="primary" animation="border" role="status" />
          <p className="mb-0">Loading...</p>
        </div>
      ) : errorMessage ? (
        <Alert variant="danger">{`There has been an error: ${errorMessage}`}</Alert>
      ) : (
        <Table size="sm" bordered={true} responsive="sm" hover className="mt-3">
          <thead>
            <tr>
              <th>Booked In Date</th>
              <th>Transport Firm</th>
              <th>Vendor</th>
            </tr>
          </thead>
          <tbody>
            {inboundDeliveries
              ? inboundDeliveries.map((el) => {
                  return (
                    <tr key={el.id}>
                      <td>{utcToLocaleDateTime(el.bookedInDate)}</td>
                      <td>{el.transportFirm ? el.transportFirm : "-"}</td>
                      <td>{el.vendorName ? el.vendorName : "-"}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default UpcomingInboundDev;
