import axios from "axios";
import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie/es6";

export function getAccessToken() {
  return sessionStorage.getItem("ACCESS_AUTH_TOKEN");
}

export function getRefreshToken() {
  const cookies = new Cookies();
  return localStorage.getItem("AUTH_TOKEN") || cookies.get("authRefreshToken");
}

export function getDecodedAccessToken() {
  const refreshAccessToken = async () => {
    const data = await axios.get(
      `${process.env.REACT_APP_AUTH_API_URL}/token/${getRefreshToken()}`
    );
    const accessToken = data.accessToken;
    sessionStorage.setItem("ACCESS_AUTH_TOKEN", accessToken);
    try {
      const decodedToken = jwt_decode(accessToken);
      return decodedToken;
    } catch (err) {
      console.error("Cannot decode refreshed access token: " + err);
      return {};
    }
  };

  try {
    const accessToken = getAccessToken();
    const decodedToken = jwt_decode(accessToken);
    return decodedToken;
  } catch (err) {
    console.error(
      "Cannot decode access token, fetching new access token and retrying: " +
        err
    );
    return refreshAccessToken();
  }
}

export function deleteTokens() {
  localStorage.removeItem("AUTH_TOKEN");
  sessionStorage.removeItem("ACCESS_AUTH_TOKEN");
  const cookies = new Cookies();
  cookies.remove("authRefreshToken", {
    path: "/",
  });
}
