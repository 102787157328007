import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { utcToLocaleDateTime } from "../../utils/timeConverter";

const OldestPickLot = ({ oldestLot }) => {
  return (
    <TableRow>
      <TableCell>{oldestLot.location}</TableCell>
      <TableCell>{oldestLot.container}</TableCell>
      <TableCell>{oldestLot.lotNo + oldestLot.A}</TableCell>
      <TableCell>{oldestLot.productTitle}</TableCell>
      <TableCell>
        {oldestLot.pickOrderLotStatus ? (
          <CheckIcon fontSize="large" />
        ) : (
          <CloseIcon fontSize="large" />
        )}
      </TableCell>
      <TableCell>{utcToLocaleDateTime(oldestLot.creationDate)}</TableCell>
      <TableCell>{oldestLot.pickOrderId}</TableCell>
      <TableCell>{oldestLot.invoiceId}</TableCell>
    </TableRow>
  );
};

export default OldestPickLot;
