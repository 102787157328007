import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { getRefreshToken } from "../utils/Tokens";

const instance = axios.create();

instance.interceptors.request.use(
  async (config) => {
    const token = await sessionStorage.getItem("ACCESS_AUTH_TOKEN");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// Set axios interceptors
const refreshAuthLogic = (failedRequest) => {
  return axios
    .get(`${process.env.REACT_APP_AUTH_API_URL}/token/${getRefreshToken()}`)
    .then((res) => {
      const accessToken = res.data.accessToken;
      sessionStorage.setItem("ACCESS_AUTH_TOKEN", accessToken);
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + accessToken;
      return Promise.resolve();
    })
    .catch((err) => Promise.reject(err));
};

createAuthRefreshInterceptor(instance, refreshAuthLogic);

export default instance;
