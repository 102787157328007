import React, { useState, useEffect } from "react";
import statsScreenNewApi from "../../API/statsScreenNewAPI";
import { Table, Row, Col, Modal } from "react-bootstrap";
import { utcToLocaleDateTime } from "../../utils/timeConverter";

const InboundDetailsModal = ({ show, handleClose, estateId }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!estateId) return;
    const getData = async () => {
      try {
        const result = await statsScreenNewApi.get(
          `${process.env.REACT_APP_AOMS_AUTH_API}/inbound/stats-collections?estateId=${estateId}`
        );
        setData(result.data);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
    return () => {
      setData([]);
    };
  }, [estateId]);

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data && data?.collections?.length > 0 && (
          <Row>
            <Col xs={12}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>PalletId</th>
                    <th>Location</th>
                    <th>Status</th>
                    <th>StartDate</th>
                    <th>EndDate</th>
                  </tr>
                </thead>
                <tbody>
                  {data.collections.map((item, i) => (
                    <tr key={i}>
                      <td>{item.id}</td>
                      <td>{item.location}</td>
                      <td>{item.status}</td>
                      <td>{utcToLocaleDateTime(item.creationDate)}</td>
                      <td>{utcToLocaleDateTime(item.modificationDate)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default InboundDetailsModal;
