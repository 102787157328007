import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import axios from "axios";

const useStyles = makeStyles(() => ({
  page: {
    minHeight: "100vh",
  },
}));

const Home = ({ history }) => {
  const classes = useStyles();

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    getAuctionHouses();

    function getAuctionHouses() {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/auctionhouses`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("ACCESS_TOKEN")}`,
          },
        })
        .then((res) => {
          setLocations(
            res.data
              .map((location) => {
                return {
                  id: location.id,
                  name: location.name.replace("Simon Charles ", ""),
                };
              })
              .reverse()
          );
        })
        .catch((err) => {
          console.error(err);
          if (err.response.status === 401) logout();
        });
    }

    function logout() {
      sessionStorage.removeItem("ACCESS_TOKEN");
      history.replace("/");
    }
  }, [history]);

  return (
    <Container maxWidth="xs">
      <Grid className={classes.page} container alignItems="center">
        {locations ? (
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Link to={{ pathname: "/dispatch", state: locations }}>
                <Button variant="outlined" fullWidth size="large">
                  Dispatch
                </Button>
              </Link>
            </Grid>
            <Grid item>
              <Link to={{ pathname: "/picking", state: locations }}>
                <Button variant="outlined" fullWidth size="large">
                  Picking
                </Button>
              </Link>
            </Grid>
            <Grid item>
              <Link to={{ pathname: "/statsScreen", state: locations }}>
                <Button variant="outlined" fullWidth size="large">
                  Stats Screen
                </Button>
              </Link>
            </Grid>
            <Grid item>
              <Link to={{ pathname: "/lotting", state: locations }}>
                <Button variant="outlined" fullWidth size="large">
                  Lotting
                </Button>
              </Link>
            </Grid>
            <Grid item>
              <Link to={{ pathname: "/returns", state: locations }}>
                <Button variant="outlined" fullWidth size="large">
                  Returns
                </Button>
              </Link>
            </Grid>
            <Grid item>
              <Link to={{ pathname: "/qms", state: locations }}>
                <Button variant="outlined" fullWidth size="large">
                  QMS
                </Button>
              </Link>
            </Grid>
            <Grid item>
              <Link to={{ pathname: "/collections", state: locations }}>
                <Button variant="outlined" fullWidth size="large">
                  Collections
                </Button>
              </Link>
            </Grid>
            <Grid item>
              <Link to={{ pathname: "/inbound", state: locations }}>
                <Button variant="outlined" fullWidth size="large">
                  Inbound
                </Button>
              </Link>
            </Grid>
          </Grid>
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Container>
  );
};

export default Home;
