import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import React, { useState, useEffect } from "react";
import OldestPickLot from "../components/OldestPickLot/OldestPickLot";
import {
  millisToReadableTime,
  utcToLocaleDateTime,
} from "../utils/timeConverter";
import axios from "axios";

const useStyles = makeStyles(() => ({
  dropDown: {
    minWidth: 120,
  },
  textRed: {
    color: "#f50057",
  },
  textGreen: {
    color: "#445A3E",
  },
}));

const Picking = ({ history, location: { state } }) => {
  const classes = useStyles();

  const [currentAuctionHouse, setCurrentAuctionHouse] = useState(
    sessionStorage.getItem("SELECTED_AUCTIONHOUSE")
      ? JSON.parse(sessionStorage.getItem("SELECTED_AUCTIONHOUSE"))
      : state[0]
  );
  const [pickingStats, setPickingStats] = useState([]);
  const [timeInMillisNow, setTimeInMillisNow] = useState(Date.now());

  useEffect(() => {
    let counter = 0;

    getPickingStats();

    const id = setInterval(() => {
      counter += 1;
      if (counter === 30) {
        getPickingStats();
        counter = 0;
      }
      setTimeInMillisNow(Date.now());
    }, 1000);

    function getPickingStats() {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/auctionhouses/${currentAuctionHouse.id}/stats/picker`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("ACCESS_TOKEN")}`,
            },
          }
        )
        .then((res) => {
          setPickingStats(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (err.response.status === 401) logout();
        });
    }

    function logout() {
      sessionStorage.removeItem("ACCESS_TOKEN");
      history.replace("/");
    }

    return () => clearInterval(id);
  }, [history, currentAuctionHouse]);

  function handleAuctionHouseChange(e) {
    const value = e.target.value;
    const selectedAuctionnHouse = state.filter(
      (auctionHouse) => auctionHouse.name === value
    )[0];

    sessionStorage.setItem(
      "SELECTED_AUCTIONHOUSE",
      JSON.stringify(selectedAuctionnHouse)
    );
    setCurrentAuctionHouse(selectedAuctionnHouse);
  }

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const fullscreenHandle = useFullScreenHandle();

  // console.log(pickingStats)

  return (
    <>
      <Grid container>
        {!isMobile && (
          <Grid container item justify="flex-end" xs={6}>
            <Button variant="outlined" onClick={fullscreenHandle.enter}>
              Enter fullscreen
            </Button>
          </Grid>
        )}
        <Grid
          container
          item
          justify={isMobile ? "center" : "flex-start"}
          xs={isMobile ? 12 : 6}
        >
          <FormControl variant="filled">
            <InputLabel htmlFor="auction-house">Auction House</InputLabel>
            <Select
              native
              value={currentAuctionHouse.name}
              onChange={handleAuctionHouseChange}
              inputProps={{
                name: "auctionHouse",
                id: "auction-house",
              }}
            >
              {state.map((auctionHouse) => {
                return (
                  <option key={auctionHouse.id} value={auctionHouse.name}>
                    {auctionHouse.name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <FullScreen handle={fullscreenHandle}>
        {pickingStats.length !== 0 ? (
          <Container maxWidth="xl">
            <Box mt={3}>
              <Grid container spacing={8}>
                <Grid container item spacing={3}>
                  <Grid container item justify="center" sm={6}>
                    <Typography
                      variant={isMobile ? "h2" : "h1"}
                    >{`To Process:`}</Typography>
                    <Typography
                      variant={isMobile ? "h2" : "h1"}
                      color="secondary"
                    >
                      {`\u00A0${pickingStats.totalLotsToProcess}`}
                    </Typography>
                  </Grid>
                  <Grid container item justify="center" sm={6}>
                    <Typography
                      variant={isMobile ? "h2" : "h1"}
                    >{`Processed:`}</Typography>
                    <Typography
                      variant={isMobile ? "h2" : "h1"}
                      color="primary"
                    >
                      {`\u00A0${pickingStats.processedToday}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item spacing={4}>
                  <Grid container item sm={6}>
                    {pickingStats.openPickBatches.length !== 0 ? (
                      <>
                        <Typography variant="h2">Open Pick Batches</Typography>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Creation Date</TableCell>
                                <TableCell>Time Open</TableCell>
                                <TableCell>Picker</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Remaining Lots</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {pickingStats.openPickBatches.map(
                                (openPickBatch) => {
                                  return (
                                    <TableRow key={openPickBatch.id}>
                                      <TableCell>
                                        {utcToLocaleDateTime(
                                          openPickBatch.creationDate
                                        )}
                                      </TableCell>
                                      <TableCell
                                        className={
                                          timeInMillisNow -
                                            new Date(
                                              openPickBatch.creationDate
                                            ) >
                                          45000 * openPickBatch.totalLots // 45 seconds per lot
                                            ? classes.textRed
                                            : ""
                                        }
                                      >
                                        {millisToReadableTime(
                                          timeInMillisNow -
                                            new Date(openPickBatch.creationDate)
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {openPickBatch.user}
                                      </TableCell>
                                      <TableCell>
                                        {openPickBatch.location}
                                      </TableCell>
                                      <TableCell>
                                        {openPickBatch.remainingLots}
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    ) : (
                      <Typography variant="h2">No Open Pick Batches</Typography>
                    )}
                  </Grid>
                  <Grid container item sm={6}>
                    {pickingStats.pickerStats.length !== 0 ? (
                      <>
                        <Typography variant="h2">Picker Scores</Typography>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Lots Picked Today</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {pickingStats.pickerStats.map((pickerStat) => {
                                return (
                                  <TableRow key={pickerStat.name}>
                                    <TableCell>{pickerStat.name}</TableCell>
                                    <TableCell className={classes.textGreen}>
                                      {pickerStat.totalLots}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    ) : (
                      <Typography variant="h2">Nothing Picked Today</Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container item sm={12}>
                  <Typography variant="h2">Oldest Lots</Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Location</TableCell>
                          <TableCell>Container</TableCell>
                          <TableCell>Lot Number</TableCell>
                          <TableCell>Product</TableCell>
                          <TableCell>Picked?</TableCell>
                          <TableCell>Creation Date</TableCell>
                          <TableCell>Pick Order Id</TableCell>
                          <TableCell>Invoice Id</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fullscreenHandle.active ? (
                          <OldestPickLot
                            oldestLot={pickingStats.oldestLots[0]}
                          />
                        ) : (
                          pickingStats.oldestLots.map((oldestLot) => {
                            return (
                              <OldestPickLot
                                key={oldestLot.lotNo}
                                oldestLot={oldestLot}
                              />
                            );
                          })
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Box>
          </Container>
        ) : (
          <CircularProgress />
        )}
      </FullScreen>
    </>
  );
};

export default Picking;
