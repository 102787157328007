import React, { useState, useEffect } from "react";
import statsScreenNewApi from "../../API/statsScreenNewAPI";
import { Table, Pagination, Row, Spinner, Alert } from "react-bootstrap";
import InvoiceDetailsModal from "./InvoiceDetailsModal";
import { utcToLocaleDateTime } from "../../utils/timeConverter";

const CollectionsIssues = ({ auctionHouse }) => {
  const [collections, setCollections] = useState();
  const [activePage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [maximumRows] = useState(30);
  const [showModal, setShowModal] = useState(false);
  const [currentInvoiceId, setCurrentInvoiceId] = useState("");
  const [paginationItems, setPaginationItems] = useState([]);

  useEffect(() => {
    const getCollections = async () => {
      try {
        setIsLoading(true);
        const result = await statsScreenNewApi.get(
          `${
            process.env.REACT_APP_AOMS_AUTH_API
          }/collections/get-collection-issues-stats?startRowIndex=${
            (activePage - 1) * maximumRows
          }&maximumRows=${maximumRows}`
        );

        setCollections(result.data);
        handleSetPagination(result.data.count);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMessage(err.message);
      }
    };
    getCollections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionHouse, activePage, maximumRows]);

  const handleShowModal = (invoiceId) => {
    setCurrentInvoiceId(invoiceId);
    setShowModal(true);
  };

  const handlePagination = (newActivePage) => {
    setActivePage(newActivePage);
  };

  const handleSetPagination = (count) => {
    const items = [];
    const range = [];
    let l = null;
    const rangeWithDots = [];
    const totalPages = Math.ceil(count / maximumRows);
    const left = activePage - 2;
    const right = activePage + 2;

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    if (rangeWithDots.length > 1) {
      for (let i = 0; i < rangeWithDots.length; i++) {
        items.push(
          <Pagination.Item
            key={i}
            active={rangeWithDots[i] === activePage}
            onClick={() =>
              rangeWithDots[i] !== "..." && handlePagination(rangeWithDots[i])
            }
            className="text-white font-weight-bold"
          >
            {rangeWithDots[i]}
          </Pagination.Item>
        );
      }
    }

    setPaginationItems(items);
  };

  return (
    <div>
      <InvoiceDetailsModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        invoiceId={currentInvoiceId}
      />
      {isLoading ? (
        <div className="d-flex flex-column my-3 justify-content-center align-items-center">
          <Spinner variant="primary" animation="border" role="status" />
          <p className="mb-0">Loading...</p>
        </div>
      ) : errorMessage ? (
        <Alert variant="danger">{errorMessage}</Alert>
      ) : (
        <Row>
          <Table striped bordered hover responsive="sm" className="mt-2">
            <thead>
              <tr>
                <th>InvoiceNo</th>
                <th>Status</th>
                <th>Customer</th>
                <th>Picker</th>
                <th>StartDate</th>
                <th>ModificationDate</th>
                <th>TimeDiff</th>
                <th>InvoiceDate</th>
                <th>CollectionDeadLine</th>
                <th>TotalLots</th>
                <th>MissingLots</th>
              </tr>
            </thead>
            <tbody>
              {collections?.result?.map((collection) => (
                <tr
                  key={collection.invoiceId}
                  onClick={() => handleShowModal(collection.invoiceId)}
                >
                  <td>{collection.invoiceId}</td>
                  <td>{collection.status}</td>
                  <td>{collection.customerName}</td>
                  <td>{collection.pickerName}</td>
                  <td>{utcToLocaleDateTime(collection.creationDate)}</td>
                  <td>{utcToLocaleDateTime(collection.modificationDate)}</td>
                  <td>{collection.timeDifference}</td>
                  <td>{utcToLocaleDateTime(collection.invoiceDate)}</td>
                  <td>{utcToLocaleDateTime(collection.collectionDeadline)}</td>
                  <td>{collection.totalLots}</td>
                  <td>{collection.lotsLeftUnpicked}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination className="d-flex flex-wrap justify-content-end">
            {activePage > 1 && (
              <Pagination.Prev
                onClick={() => {
                  const previousPage = activePage - 1;
                  handlePagination(previousPage);
                }}
              />
            )}
            {paginationItems}
            {activePage !== paginationItems.length && (
              <Pagination.Next
                onClick={() => {
                  const nextPage = activePage + 1;
                  handlePagination(nextPage);
                }}
              />
            )}
          </Pagination>
        </Row>
      )}
    </div>
  );
};

export default CollectionsIssues;
