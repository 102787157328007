import React, { useState, useEffect } from "react";
import { Table, Pagination, Col, Row, Spinner, Alert } from "react-bootstrap";
import { utcToLocaleDateTime } from "../../utils/timeConverter";
import statsScreenNewApi from "../../API/statsScreenNewAPI";

const PickingWorkflow = ({ auctionHouse }) => {
  const [pickingWorkflow, setPickingWorkflow] = useState();
  const [activePage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const maximumRows = 50;

  useEffect(() => {
    const getPickingWorkflow = async () => {
      try {
        setIsLoading(true);
        const response = await statsScreenNewApi.get(
          `${process.env.REACT_APP_AOMS_AUTH_API}/auctionhouses/pickingWorkflow/${
            auctionHouse.id
          }?startRowIndex=${
            (activePage - 1) * maximumRows
          }&maximumRows=${maximumRows}`
        );

        setPickingWorkflow(response.data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setErrorMessage(err.message);
      }
    };

    getPickingWorkflow();
  }, [auctionHouse, activePage, maximumRows]);

  const handlePagination = (newActivePage) => {
    setActivePage(newActivePage);
  };

  const handleStatusColor = (status) => {
    if (status === 3) return { backgroundColor: "red", color: "white" };
    if (status === 8) return { backgroundColor: "green", color: "white" };
    if (status === 9) return { backgroundColor: "purple", color: "white" };
    return { backgroundColor: "white", color: "black" };
  };

  let paginationItems = [];
  if (pickingWorkflow) {
    for (
      let noOfItems = pickingWorkflow.count, pageNumber = 1;
      noOfItems > 0;
      noOfItems -= maximumRows, pageNumber++
    ) {
      paginationItems.push(
        <Pagination.Item
          key={pageNumber}
          active={pageNumber === activePage}
          onClick={() => {
            handlePagination(pageNumber);
          }}
          className="text-white font-weight-bold"
        >
          {pageNumber}
        </Pagination.Item>
      );
    }
  }

  return (
    <>
      {isLoading ? (
        <div className="d-flex flex-column my-3 justify-content-center align-items-center">
          <Spinner variant="primary" animation="border" role="status" />
          <p className="mb-0">Loading...</p>
        </div>
      ) : errorMessage ? (
        <Alert variant="danger">{`There has been an error: ${errorMessage}`}</Alert>
      ) : (
        <Row>
          <Row>
            <Col className="d-flex align-items-center justify-content-center">
              <span>Ready</span>
              <span className="statusType" style={handleStatusColor(8)}></span>
            </Col>
            <Col className="d-flex align-items-center justify-content-center">
              <span>Picked</span>
              <span
                className="statusType align-items-center"
                style={handleStatusColor(9)}
              ></span>
            </Col>
            <Col className="d-flex align-items-center justify-content-center">
              <span>Pending</span>
              <span className="statusType" style={handleStatusColor()}></span>
            </Col>
            <Col className="d-flex align-items-center justify-content-center">
              <span>Issue</span>
              <span className="statusType" style={handleStatusColor(3)}></span>
            </Col>
          </Row>
          <Table
            size="sm"
            bordered={true}
            responsive="sm"
            hover
            className="mt-3"
          >
            <thead>
              <tr>
                <th>Pick Order Id</th>
                <th>Invoice Id</th>
                <th>Creation Date</th>
                <th>Endpoint</th>
                <th>Total Lots</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              {pickingWorkflow &&
                pickingWorkflow.data.map((el) => {
                  return (
                    <tr key={el.id} style={handleStatusColor(el.status)}>
                      <td>{el.id}</td>
                      <td>{el.invoiceId}</td>
                      <td>{utcToLocaleDateTime(el.creationDate)}</td>
                      <td>{el.endpoint === 0 ? "Collection" : "Delivery"}</td>
                      <td>{el.totalLots}</td>
                      <td>{el.location}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <Pagination className="d-flex flex-wrap justify-content-end">
            {activePage > 1 && (
              <Pagination.Prev
                onClick={() => {
                  const previousPage = activePage - 1;
                  handlePagination(previousPage);
                }}
              />
            )}
            {paginationItems}
            {activePage !== paginationItems.length && (
              <Pagination.Next
                onClick={() => {
                  const nextPage = activePage + 1;
                  handlePagination(nextPage);
                }}
              />
            )}
          </Pagination>
        </Row>
      )}
    </>
  );
};

export default PickingWorkflow;
