import React from "react";
import OldestPickLot2 from "./OldestPickLot2";

import Table from "react-bootstrap/Table";

const OldestPendingOrder = ({ pickingInfo }) => {
  return (
    <Table bordered={true}  hover className="mt-3">
      <thead>
        <tr>
          <th>Location</th>
          <th>Container</th>
          <th>Lot Numer</th>
          <th>Product</th>
          <th>Picked?</th>
          <th>Creation Date</th>
          <th>Pick Order Id</th>
        </tr>
      </thead>
      <tbody>
        {pickingInfo.oldestLots
          ? pickingInfo.oldestLots.map((el) => {
              return <OldestPickLot2 lots={el} key={el.lotNo} />;
            })
          : null}
      </tbody>
    </Table>
  );
};

export default OldestPendingOrder;
